

import b1 from "../assets/images/brand/b1.png"
import b2 from "../assets/images/brand/b2.png"
import b3 from "../assets/images/brand/b3.jpg"
import b4 from "../assets/images/brand/b4.jpg"
import b5 from "../assets/images/brand/b5.jpg"
import b6 from "../assets/images/brand/b6.jpg"


export const BRAND_LIST = [
  { id: 1, src: b1 },
  { id: 2, src: b2 },
  { id: 3, src: b3 },
  { id: 4, src: b4 },
  { id: 5, src: b5 },
  { id: 6, src: b6 },
];
